<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <validation-observer v-slot="{ handleSubmit }">
      <v-form
        ref="form"
        lazy-validation
        @submit.prevent="handleSubmit(onAdd)"
      >
        <v-row>
          <v-col>
            <!-- for admin  -->
            <v-card v-if="userType === 1 || userType === 2">
              <v-card-title primary-title>
                <span class="custome-color-note text-h5">{{
                  $t('messages.parttimer_pick')
                }}</span>
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-row>
                      <v-col
                        class=" align-center d-flex mb-6 pr-0 "
                        cols="3"
                      >
                        <div>
                          <h3>
                            <span class="font-weight-bold">{{
                              $t('messages.employee_id')
                            }}</span>
                          </h3>
                        </div>
                      </v-col>
                      <v-col cols="9">
                        <v-row>
                          <v-col
                            cols="6"
                            class="pr-0"
                          >
                            <div class="align-self-center mr-9">
                              <validation-provider
                                v-slot="{ errors }"
                                ref="searchEmployeeId"
                                name="searchEmployeeId"
                                rules="integer"
                              >
                                <v-text-field
                                  v-model="searchEmployeeId"
                                  dense
                                  single-line
                                  outlined
                                  :error-messages="errors[0]"
                                  @keydown.enter="onSearchEmployeeManageId"
                                />
                              </validation-provider>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="5">
                    <v-row class="d-flex justify-end">
                      <v-col cols="3">
                        <v-btn
                          block
                          color="accent"
                          class="text-h4"
                          @click="onSearchEmployeeManageId()"
                        >
                          {{ $t('messages.search') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    <span class="font-weight-bold">{{
                      $t('table.headers.manageId')
                    }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="font-weight-bold">{{
                      $t('table.headers.furiganaName')
                    }}</span>
                  </v-col>
                  <v-col cols="3">
                    <span class="font-weight-bold">
                      {{ $t('table.headers.name') }}</span>
                  </v-col>
                  <v-col cols="2">
                    <span class="font-weight-bold">
                      {{ $t('table.headers.sex') }}</span>
                  </v-col>
                  <v-col cols="2">
                    <span class="font-weight-bold">
                      {{ $t('table.headers.dob') }}</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="2">
                    {{ currentEmployee.manageId }}
                  </v-col>
                  <v-col cols="3">
                    {{ currentEmployee.furiganaName }}
                  </v-col>
                  <v-col cols="3">
                    {{ currentEmployee.name }}
                  </v-col>
                  <v-col cols="2">
                    <span v-if="currentEmployee.sex != null">
                      {{ currentEmployee.sex ? $t('messages.male') : $t('messages.female') }}
                    </span>
                  </v-col>
                  <v-col cols="2">
                    {{ currentEmployee.dob | formatDate }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="10">
            <v-card>
              <v-card-title primary-title>
                <span class="h3">{{ $t('table.titles.worktime_view') }}</span>
              </v-card-title>
              <v-card-text>
                <v-row align="center">
                  <v-col>
                    <v-sheet
                      tile
                      height="54"
                      class="d-flex"
                    >
                      <v-row align="center">
                        <v-col
                          class="pl-1"
                          cols="3"
                        >
                          <v-select
                            v-model="type"
                            :items="types"
                            item-text="value"
                            item-value="key"
                            dense
                            outlined
                            hide-details
                            class="ma-2"
                          >
                            <template v-slot:label>
                              <div class="font-weight-bold ">
                                {{ $t('messages.viewOption') }}
                              </div>
                            </template>
                          </v-select>
                        </v-col>
                        <v-col
                          class="pl-1"
                          cols="3"
                        >
                          <v-menu
                            ref="menu2"
                            v-model="menuIntervalPicker"
                            :close-on-content-click="false"
                            :nudge-right="20"
                            :return-value.sync="intervalIndex.value"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="intervalIndex.display"
                                name="actualEndAt"
                                v-bind="attrs"
                                autocomplete="off"
                                readonly
                                v-on="on"
                              >
                                <template v-slot:label>
                                  <div class="font-weight-bold">
                                    {{ $t('messages.intervalIndex') }}
                                  </div>
                                </template>
                              </v-text-field>
                            </template>
                            <v-date-picker
                              v-if="menuIntervalPicker"
                              v-model="intervalIndex.value"
                              full-width
                              :range="intervalIndex.isRange"
                              locale="ja"
                              :show-current="true"
                              :show-week="true"
                              :type="intervalIndex.type"
                              :no-title="true"
                              format="24hr"
                              @click:date="saveInterval"
                              @click:month="saveInterval"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          offset="2"
                          cols="2"
                        >
                          <span class="font-weight-bold text-h3 accent--text">
                            {{
                              $moment(calendar.value).format('YYYY年 - MM月 ')
                            }}</span>
                        </v-col>
                        <v-col class="d-flex justify-end">
                          <v-btn
                            icon
                            class="ma-2"
                            @click="$refs.calendar.prev()"
                          >
                            <v-icon>mdi-chevron-left</v-icon>
                          </v-btn>
                          <v-btn
                            icon
                            class="ma-2"
                            @click="$refs.calendar.next()"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-sheet>
                    <v-sheet
                      class="mt-3"
                      height="600"
                    >
                      <v-calendar
                        ref="calendar"
                        v-model="calendarValue"
                        locale="ja"
                        :weekdays="weekday"
                        :events="events"
                        :event-overlap-mode="mode"
                        :event-overlap-threshold="30"
                        :event-color="getEventColor"
                        :type="type"
                      >
                        <template v-slot:event="props">
                          <span class="ml-2">{{ props.event.name || '' }}</span>
                        </template>
                      </v-calendar>
                    </v-sheet>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions />
            </v-card>
          </v-col>
          <v-col>
            <v-row
              v-for="(item, key, index) in typeToColor"
              :key="index"
            >
              <v-col>
                <v-card :color="item">
                  <v-card-text>
                    <span class="black--text">{{ $t('messages.' + key) }}</span>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
  import { required, email, min } from 'vee-validate/dist/rules'
  import moment from 'moment'
  import _ from 'lodash'

  export default {
    name: 'ParttimerWorkTimeView',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        currentEmployee: {
          manageId: '',
          name: '',
          furiganaName: '',
          sex: null,
          dob: '',
        },
        searchEmployeeId: '',
        userType: '',
        userId: '',

        workDays: [],
        workHours: [],

        fromTime: '',
        toTime: '',
        selectEventToTime: '',
        selectEventFromTime: '',

        intervalIndex: {
          value: [],
          type: 'date',
          display: '',
          isRange: true,
        },
        type: 'week',
        types: [],
        mode: 'stack',
        modes: ['stack', 'column'],
        weekday: [0, 1, 2, 3, 4, 5, 6],
        colors: [
          'blue',
          'indigo',
          'deep-purple',
          'cyan',
          'green',
          'orange',
          'grey darken-1',
        ],
        events: [],
        calendar: {
          today: '',
          start: '',
          value: '',
          end: '',
        },
        calendarValue: '',
        typeToColor: {
          workedtime: '#F0D713',
          absentTime: '#B5B5C3',
          assignedWorkTime: '#1BC4BD',
          plannedWorktime: '#BEADDF',
        },
        menuIntervalPicker: false,
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,

        response: [],
        workEvents: [],
        absentEvents: [],
        plannedWorkEvents: [],
      }
    },
    computed: {
      ...get('parttimer', [
        'message',
        'status',
        'error',
        'parttimeEmployee',
        'colleges',
      ]),
      unavailableTimes: get('parttimer@unavailbleTimes'),
      employeeDetail: get('parttimer@list'),
      worktimesEvent: get('parttimer@employeesWorktimes'),
    },
    watch: {
      calendarValue (value) {
        this.calendar.value = value
        if (this.userId) {
          this.getEvents(this.userId)
        }
      },
      status (value) {
        if (value === 'success') this.showSnackBar('messages.success')
        if (value === 'error') this.showSnackBar('messages.server_error')
      },
      singleDate () {
        this.renderWorksHour()
      },
      fromDate () {
        this.renderWorksHour()
      },
      toDate () {
        this.renderWorksHour()
      },
      parttimeEmployee (value) {
        this.currentEmployee = _.get(value, 'data.parttimeEmployee', '')
        this.userId = _.get(value, 'data.parttimeEmployee.id', '')
        if (this.userId) {
          this.getEvents(this.userId)
        }
      },
      worktimesEvent (value) {
        this.response = value
      },
      dateRadioCheckBox (value) {
        this.singleDate = ''
        this.fromDate = ''
        this.toDate = ''
      },
      response () {
        this.initEvents()
      },
      error (value) {
        if (value) {
          this.snackbarMessage = this.$t(value)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      unavailableTimes (value) {
        this.events = value.map(event => {
          return {
            start: moment.utc(event.startAt).format('YYYY-MM-DD HH:mm'),
            end: moment.utc(event.endAt).format('YYYY-MM-DD HH:mm'),
            type: 'unavailableTime',
          }
        })
      },
      intervalIndex: {
        handler: function (data) {
          if (data.value) {
            let valueDate
            if (Array.isArray(data.value) && data.value.length !== 0) {
              valueDate = moment(data.value[0])
            } else valueDate = moment(data.value)

            this.calendar.value = valueDate.format('YYYY-MM-DD')
            this.calendarValue = valueDate.format('YYYY-MM-DD')
          }
        },
        deep: true,
      },

      type (value) {
        this.intervalIndex.isRange = false
        this.intervalIndex.display = ''
        if (value === 'month') {
          this.intervalIndex.type = 'month'
          this.intervalIndex.value = ''
        }
        if (value === 'week') {
          this.intervalIndex.type = 'date'
          this.intervalIndex.value = []
          this.intervalIndex.isRange = true
        }
        if (value === 'day') {
          this.intervalIndex.type = 'date'
          this.intervalIndex.value = ''
        }

        this.getEvents(this.userId)
      },
    },
    created () {
      this.initSetting()
      this.userType = JSON.parse(
        JSON.parse(localStorage.getItem('user')).data,
      ).type
    },
    mounted () {
      if (this.userType === 4) {
        this.userId = JSON.parse(
          localStorage.getItem('user'),
        ).detail.typeCorrespondId
      }

      this.initEvents()

      this.types = [
        { key: 'month', value: this.$t('messages.byMonth') },
        { key: 'week', value: this.$t('messages.byWeek') },
        { key: 'day', value: this.$t('messages.today') },
      ]

      extend('integer', {
        validate (value) {
          return /^\+?(0|[1-9]\d*)$/.test(value)
        },
        message: this.$t('validator.must_be_number'),
      })
    },
    methods: {
      getEvents (id) {
        if (!id) return
        let startAt = ''
        let endAt = ''
        if (this.type === 'week') {
          startAt = moment(this.calendar.value, 'YYYY-MM-DD').startOf('week')
          endAt = moment(this.calendar.value, 'YYYY-MM-DD').endOf('week')
        } else if (this.type === 'month') {
          startAt = moment(this.calendar.value, 'YYYY-MM-DD').startOf('month')
          endAt = moment(this.calendar.value, 'YYYY-MM-DD').endOf('month')
        } else if (this.type === 'day') {
          startAt = moment(this.calendar.value, 'YYYY-MM-DD')
          endAt = moment(this.calendar.value, 'YYYY-MM-DD')
        }
        const payload = {
          id: id,
          startAt: startAt.format('YYYY-MM-DD'),
          endAt: endAt.format('YYYY-MM-DD'),
        }
        this.$store.dispatch('parttimer/getEmployeeWorktime', payload)
      },
      saveInterval (date) {
        if (this.intervalIndex.isRange) {
          const startOfWeek = moment(date)
            .startOf('week')
            .format('YYYY-MM-DD')
          const endOfWeek = moment(date)
            .endOf('week')
            .format('YYYY-MM-DD')
          this.intervalIndex.value = [startOfWeek, endOfWeek]
          this.intervalIndex.display =
            moment(startOfWeek).format('MM月 DD日') +
            ' から ' +
            moment(endOfWeek).format('MM月 DD日') +
            'まで'
        } else {
          this.intervalIndex.display =
            this.type === 'day'
              ? moment(date).format('MM月 DD日')
              : moment(date).format('MM月')
          this.intervalIndex.value = date
        }
        this.$refs.menu2.save(this.intervalIndex.value)
      },
      getUnavailableTimeByParttimeEmployeeId (id) {
        if (!id) return
        this.$store.dispatch('parttimer/getUnavailableTimeByParttimeEmployeeId', {
          id: id,
        })
      },

      getEventColor (event) {
        const color = this.typeToColor[event.type]
        return color || '#FFFFFF'
      },
      initSetting () {
        const FORMAT_STRING = 'YYYY-MM-DD'
        const today = moment().format(FORMAT_STRING)

        this.calendar.start = moment()
          .startOf('month')
          .format(FORMAT_STRING)
        this.calendarValue = today
      },
      initEvents () {
        this.events = []

        this.response.forEach(res => {
          const record = res.record
          record.forEach(element => {
            if (element.isPresent === null) {
              const reFormat = moment(element.plannedEndAt).subtract(1, 'seconds')
              this.events.push({
                start:
                  moment.utc(element.plannedStartAt).format('YYYY-MM-DD HH:mm:ss'),
                end:
                  moment.utc(reFormat).format('YYYY-MM-DD HH:mm:ss'),
                name: _.get(element, 'shift.job.customer.user.name', '') + ' - ' + _.get(element, 'shift.job.name', ''),
                type: 'assignedWorkTime',
              })
            } else {
              if (element.isPresent) {
                const reFormat = moment(element.actualEndAt).subtract(1, 'seconds')
                this.events.push({
                  start:
                    moment.utc(element.actualStartAt).format('YYYY-MM-DD HH:mm:ss'),
                  end:
                    moment.utc(reFormat).format('YYYY-MM-DD HH:mm:ss'),
                  name: _.get(element, 'shift.job.customer.user.name', '') + ' - ' + _.get(element, 'shift.job.name', ''),
                  type: 'workedtime',
                })
              } else {
                const reFormat = moment(element.plannedEndAt).subtract(1, 'seconds')
                const event = {
                  start:
                    moment.utc(element.plannedStartAt).format('YYYY-MM-DD HH:mm:ss'),
                  end:
                    moment.utc(reFormat).format('YYYY-MM-DD HH:mm:ss'),
                  name: _.get(element, 'shift.job.customer.user.name', '') + ' - ' + _.get(element, 'shift.job.name', ''),
                  type: 'absentTime',
                }
                this.events.push(event)
              }
            }
          })
          const plan = res.plan

          plan.forEach(element => {
            let startTime = this.converNumber(element.startAt)
            let endTime = this.converNumber(element.endAt)
            let startAt = moment(element.plannedDate + ' ' + element.startAt)
            let endAt = moment(element.plannedDate + ' ' + element.endAt)

            if (parseFloat(startTime) > 24) {
              startTime = parseFloat(startTime) - 24
              startAt = moment(element.plannedDate + ' ' + this.convertTime(startTime)).add(1, 'days')
            }

            if (parseFloat(endTime) > 24) {
              endTime = parseFloat(endTime) - 24
              endAt = moment(element.plannedDate + ' ' + this.convertTime(endTime)).add(1, 'days')
            }

            if (parseFloat(startTime) > parseFloat(endTime)) {
              endAt = moment(element.plannedDate + ' ' + this.convertTime(endTime)).add(1, 'days')
            }

            this.events.push({
              start: startAt.format('YYYY-MM-DD HH:mm:ss'),
              end: endAt.format('YYYY-MM-DD HH:mm:ss'),
              name: _.get(element, 'shift.job.customer.user.name', '') + ' - ' + _.get(element, 'shift.job.name', ''),
              type: 'plannedWorktime',
            })
          })
        })
      },
      converNumber (str) {
        const timeStart = parseInt(str.slice(0, 2))
        const timeEnd = (parseInt(str.slice(3, 5)) / 60)
        return parseFloat(timeStart + timeEnd).toFixed(2)
      },
      convertTime (float) {
        let hour = parseInt(float)
        let minute = String(Math.round((float - parseInt(float)) * 60))

        if (hour.length === 1) {
          hour = '0' + hour
        }

        if (minute.length === 1) {
          minute = '0' + minute
        }

        return hour + ':' + minute
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInterval) {
            clearInterval(this.snackbarCloseInterval)
          }
          this.snackbarCloseInterval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },

      onSearchEmployeeManageId () {
        if (!this.searchEmployeeId) return
        const id = this.searchEmployeeId.trim()

        this.searchEmployeeManageId(id)
      },
      searchEmployeeManageId (manageId) {
        this.currentEmployee = {
          manageId: '',
          name: '',
          furiganaName: '',
          sex: null,
          dob: '',
        }

        this.userId = ''

        if (!manageId) return
        const payload = { id: manageId }
        this.$store.dispatch('parttimer/getParttimerEmployee', payload)
      },
      searchEmployeeUserId (id) {
        if (!id) return
        const payload = { keyword: id }

        this.$store.dispatch('user/getProfile', payload)
      },
    },
  }
</script>
<style lang="scss">
  .custome-color-note {
    color: #8da0e4
  }
</style>
